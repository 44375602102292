export const zones = [
        ['book', 'Books'],
        ['picture', 'Pictures'],
        ['article', 'Articles'],
        ['music', 'Music'],
        ['map', 'Maps'],
        ['collection', 'Collections'],
        ['newspaper', 'Newspapers'],
        ['gazette', 'Gazettes']
    ]

//version 3 update
//v2 Trove API ‘zone’ parameter values include: all, book, picture, article, music, map, collection, newspaper, gazette, list 
//v3 Trove API ‘category’ parameter values include: all, newspaper, magazine, image, research, book, diary, music, people, list 
export const categories = [
    ['newspaper', 'Newspapers'],
    ['magazine','Magazines'],
    ['image','Images'],
    ['research','Research'],
    ['book', 'Books'],
    ['diary','Diaries'],
    ['music', 'Music'],
    ['people','People'],
    ['list','List'],
]