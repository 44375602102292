import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Container, List, Loader, Segment } from 'semantic-ui-react'
import ResultItem from '../Components/ResultItem'
import { doingSearch, getWork, setCurrentPageIndex, setSearchZone } from '../Store/actions'
import Branding from '../Components/Branding'
import { zones, categories } from '../Components/zones'


class ResultsPage extends Component {

    componentDidMount = () => {
    }

    prev = () => {
        const { currentPageIndex, setCurrentPageIndex } = this.props
        let i = currentPageIndex - 1
        if (i < 0) i = 0
        setCurrentPageIndex(i)
    }


    next = () => {
        const {
            getWork,
            searchNames,
            searchKeyword,
            searchZone,
            searchType,
            nextStart,
            pages,
            currentPageIndex,
            setCurrentPageIndex
        } = this.props
        // If current page is the last in pages, get new data
        // else just get next page from redux state
        if (currentPageIndex >= pages.length-1) {
            let names = searchNames.filter(name => name.selected)
            names = names.map(item => item.name)
            getWork(names, searchZone, nextStart, searchType)
        } else {
            setCurrentPageIndex(currentPageIndex + 1)
        }
    }

    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

        // Press a zone button
    handleChangeZoneButton = zone => {
        const { searchNames, searchType, searchKeyword } = this.props
        let names = '';
        if (searchType === 'language') {
            names = searchNames.filter(name => name.selected)
            names = names.map(item => item.name)
        }  else if (searchType === 'keyword') {
            names=searchKeyword;
        }
        this.props.changeZone(names, zone, '*', searchType)
    }

    render() {
        const {
            history,
            searchStatus,
            searchNames,
            searchZone,
            searchType,
            searchKeyword,
            total,
            pages,
            currentPageIndex
        } = this.props

        //console.log("searchNames", searchNames)

        const currentPage = pages[currentPageIndex] ? pages[currentPageIndex] : []
        //console.log("currentPage", currentPage)

        const items = currentPage.work
        //console.log('items',items);

        let resultListEls
        if (items && items.length > 0) {
            resultListEls = items.map((item, i) => (
                <ResultItem item={item} key={i} />
            ))
        }
        // if (searchStatus == "searching") resultListEls = <p>Searching</p>
        const numPages = Math.ceil(total / 20) // hard code the number per page
        const nav = (
            <Container className = "section search-button-container">
                <Button onClick={this.prev} disabled={currentPageIndex === 0} content='Previous' />
                <Button onClick={this.next} disabled={currentPageIndex + 1 >= numPages} content='Next' />
                <Loader active={searchStatus === 'searching'} size="massive">Searching Trove...</Loader>
            </Container>
        )

        // break apart names that are slash-separated
        let namesSeparated = searchNames.filter(name => name.selected).map(item => item.name.split(/\s?\/\s?/))
        // flatten the array
        namesSeparated = [...new Set([].concat.apply([], namesSeparated))]
        const resultsForNames = namesSeparated.join(", ")

        // Kinda weird data format. Zones is an array of arrays in the format [[zone, human-readable], ...]
        // So first filter on the zone, get the [0] of filter results, then get the [1] item in the matching array
        const zoneLabel = categories.filter(cat => (cat[0] === searchZone))[0][1]

        return (
            <div className="results page">
                <Container>

                    <Branding />


                    <Segment className="zone-buttons">
                        {categories.map(cat => (
                            <Button key={cat[0]} positive={cat[0] === searchZone} onClick={e => this.handleChangeZoneButton(cat[0])}>{cat[1]}</Button>
                        ))}
                    </Segment>

                    <div className="header">
                        <h1>Results for { resultsForNames } {zoneLabel} </h1>
                        <div className="page-count">
                            {!isNaN(numPages) && numPages > 0 &&
                            <p>
                                {currentPageIndex + 1} of {this.formatNumber(numPages)} pages
                            </p>
                            }
                        </div>
                    </div>

                    {nav}

                    {searchStatus === 'ready' &&
                    // If we aren't currently searching, return to the home page
                    history.push("/")
                    }

                    {resultListEls &&
                    // we have results
                    <List divided relaxed className="results">
                        {resultListEls}
                    </List>
                    }

                    {!items && searchStatus === 'done' &&
                    <p className="center message">No items found.</p>
                    }

                    {nav}

                </Container>
            </div>
        )

    }
}

const mapStateToProps = state => ({
    searchStatus: state.searchStatus,
    searchNames: state.searchNames,
    searchZone: state.searchZone,
    searchType: state.searchType,
    searchKeyword: state.searchKeyword,
    s: state.s,
    nextStart: state.nextStart,
    total: state.total,
    n: state.n,
    pages: state.pages,
    currentPageIndex: state.currentPageIndex
})

const mapDispatchToProps = dispatch => ({
    getWork: (text, names, zone, s) => {
        dispatch(doingSearch())
        dispatch(getWork(text, names, zone, s))
    },
    setCurrentPageIndex: index => {
        dispatch(setCurrentPageIndex(index))
    },
    changeZone: ( names, zone, s, searchType) => {
        console.log("searchType", searchType, 'names',names)
        dispatch(setSearchZone(zone))
        dispatch(doingSearch())
        dispatch(getWork(names, zone, s, searchType))
    }
})

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResultsPage)
)
