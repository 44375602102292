import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import SearchPage from '../Components/SearchPage'
import ResultsPage from '../Components/ResultsPage'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Route exact path="/" component={SearchPage} />
          <Route path="/results" component={ResultsPage} />
        </div>
      </BrowserRouter>
    )
  }
}

export default App
