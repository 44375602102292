import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import App from './Components/App';
import { Provider } from 'react-redux'
import store from './Store/store'
import ReactGA from "react-ga4";

ReactGA.initialize("G-C9TPB0FW65");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
