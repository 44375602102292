import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Image } from 'semantic-ui-react'
import header from '../images/header.png'

function Branding(props) {
  return (
    <Container>
      <Link to='/'><Image className="logo" src={header} /></Link>
    </Container>
  )
}
export default Branding;

