import React from 'react'
import { ListItem } from 'semantic-ui-react'

function ResultItem(props) {
    const { item } = props

    // const linkText = (Array.isArray(item.snippet)) ? item.snippet[0] : item.snippet
    // dangerouslySetInnerHTML = {{ __html: linkText }}

    const contributor = (item.hasOwnProperty("contributor") && Array.isArray(item.contributor)) ?
        item.contributor.join(",") :
        item.contributor

    let title = ''
    if (item.hasOwnProperty("title")) {
        title = item.title
    }
    if (item.hasOwnProperty("heading")) {
        title = item.heading
    }

    return (
        <ListItem className="result-item">
            <h2 className="title">
                <a href={item.troveUrl} target="trove">{title}</a>
            </h2>
            <p className="contributor">
                { contributor }
            </p>
        </ListItem>
    )
}
export default ResultItem;

