// actions.js

//import AwesomeDebouncePromise from 'awesome-debounce-promise'
//import jsonp from 'jsonp-promise'

export const error = ex => {
    console.error(ex)
    return { type: 'ERROR', payload: ex }
}

//const searchAPI = async (url) => {
    //return await jsonp(url).promise
//}
//const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500)

export const setCurrentPageIndex = index => {
    return { type: 'SET_CURRENT_PAGE_INDEX', index }
}

export const setSearchName = name => {
    return { type: 'SET_NAME_TEXT', name }
}
export const setSearchKeyword = name => {
    return { type: 'SET_KEYWORD_TEXT', name }
}
export const setSearchType = name => {
    return { type: 'SET_SEARCH_TYPE', name }
}

export const setSearchZone = zone => {
    console.log("actions set search zone")
    return { type: 'SET_SEARCH_ZONE', zone }
}

export const setCodes = codes => {
    return { type: 'SET_CODES', codes }
}

export const setSelectedNames = names => {
    return { type: 'SET_SELECTED_NAMES', names }
}

export const receivedApiResponse = data => {
    return { type: 'RECEIVED_API_RESPONSE', data }
}

export const getWork = (names, zone, s, type) => async dispatch => {

    //console.log('GET WORK',names, zone, s, type);

    let query = ''

    if ( type === 'language' ) {
        // break apart names that are slash-separated. Could extend this for other separators too
        let namesSeparated = names.map(name => name.split(/\s?\/\s?/))

        // flatten the array
        namesSeparated = [...new Set([].concat.apply([], namesSeparated))]

        if (names) query += namesSeparated.map(name => encodeURIComponent(name)).join("+OR+")

    } else if ( type === 'keyword' ) {
        query=names;
    }
    
    //https://api.trove.nla.gov.au/v3/result?key=<YOUR KEY>&category=book&q=australian&l-austlanguage= Warlpiri%20C15&s=*&n=20&sortBy=relevance&bulkHarvest=true&reclevel=full&encoding=xml 

    let url =
        'https://api.trove.nla.gov.au/v3/result?key=8odl5mlnqm4pr15a' +
        '&q=' + query +
        '&category=' + zone +
        '&encoding=json' +
        '&s=' + encodeURIComponent(s)
        //console.log(url)
    try {
        const response = await fetch(url);
        console.log('response',response)
        if (response.ok) {
            //HTTP status ok
            const data = response.json();
            dispatch(receivedApiResponse(data))
        } else {
            //http status not ok
            console.log(`HTTP error! status: ${response.status}`);
        }
    } catch {
        //promise rejected
        console.log(`promise rejected`);
    }

}

export const doingSearch = () => {
    return { type: 'DOING_SEARCH' }
}
